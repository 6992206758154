import {
  SparklesIcon,
  PencilRulerIcon,
  MousePointerClickIcon,
  GraduationCapIcon,
} from 'lucide-react';
import type { MetaFunction } from '@remix-run/node';

import discoLogo from '@admin/assets/disco-logo-2.svg';
import { Link, Outlet } from '@remix-run/react';
import { RainbowButton } from '@admin/components/ui/rainbow-button';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from '@admin/components/ui/navigation-menu';
import { useDeployEnv } from '@admin/components/utils';

export const meta: MetaFunction = () => [
  {
    title: 'AI Content Studio',
  },
];

// MARK: Main Component
export default function Marketing() {
  const deployEnv = useDeployEnv();

  return (
    <div>
      <div className="container sticky top-3 z-20 mx-auto">
        {deployEnv !== 'production' && (
          <nav className="z-20 m-3 mx-8 rounded-full border border-slate-200/50 bg-white/80 p-1 px-2 shadow-lg backdrop-blur-md">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Link to="/">
                  <img src={discoLogo} className="h-10 w-auto p-2" alt="Disco" />
                </Link>
                <NavigationMenu>
                  <NavigationMenuList>
                    <NavigationMenuItem>
                      <NavigationMenuTrigger>Features</NavigationMenuTrigger>
                      <NavigationMenuContent className="grid min-w-[600px] grid-cols-2 gap-4 p-2">
                        <Link
                          to="/#ai-gen"
                          className="flex cursor-pointer flex-col gap-2 rounded p-3 px-6 hover:bg-gray-100"
                        >
                          <div className="flex items-center gap-2">
                            <SparklesIcon className="h-6 w-6" />
                            <span>AI course generation</span>
                          </div>
                          <p className="text-sm opacity-70">
                            Let AI generate complete trainings for you.
                          </p>
                        </Link>
                        <Link
                          to="/#e-learning-editor"
                          className="flex cursor-pointer flex-col gap-2 rounded p-3 px-6 hover:bg-gray-100"
                        >
                          <div className="flex items-center gap-2">
                            <PencilRulerIcon className="h-6 w-6" />
                            <span>Course Editor</span>
                          </div>
                          <p className="text-sm opacity-70">
                            Create professional e-learning courses with ease.
                          </p>
                        </Link>
                        <Link
                          to="/#component-library"
                          className="flex cursor-pointer flex-col gap-2 rounded p-3 px-6 hover:bg-gray-100"
                        >
                          <div className="flex items-center gap-2">
                            <MousePointerClickIcon className="h-6 w-6" />
                            <span>Interactive Components</span>
                          </div>
                          <p className="text-sm opacity-70">
                            Award winning components, ready to use.
                          </p>
                        </Link>
                        <Link
                          to="/#lms"
                          className="flex cursor-pointer flex-col gap-2 rounded p-3 px-6 hover:bg-gray-100"
                        >
                          <div className="flex items-center gap-2">
                            <GraduationCapIcon className="h-6 w-6" />
                            <span>Integrated LMS</span>
                          </div>
                          <p className="text-sm opacity-70">
                            Manage e-learning courses & certificates.
                          </p>
                        </Link>
                      </NavigationMenuContent>
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                      <NavigationMenuTrigger>Comparisons</NavigationMenuTrigger>
                      <NavigationMenuContent className="grid min-w-[600px] grid-cols-2 gap-4 p-2">
                        <Link
                          to="/comparison/courseau"
                          className="flex cursor-pointer flex-col gap-2 rounded p-3 px-6 hover:bg-gray-100"
                        >
                          <div className="flex items-center gap-2">
                            <span>Compare with Courseau</span>
                          </div>
                          <p className="text-sm opacity-70">
                            Disco utilizes a unique input-to-interactive AI concept. See
                            how it compares to Courseau.
                          </p>
                        </Link>
                        <Link
                          to="/comparison/articulate-rise-360"
                          className="flex cursor-pointer flex-col gap-2 rounded p-3 px-6 hover:bg-gray-100"
                        >
                          <div className="flex items-center gap-2">
                            <span>Compare with Articulate Rise</span>
                          </div>
                          <p className="text-sm opacity-70">
                            Disco provides unique benefits like full course AI generation
                            and in-depth custom styling. Explore more differences here.
                          </p>
                        </Link>
                      </NavigationMenuContent>
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                      <Link to="/#pricing" className={navigationMenuTriggerStyle()}>
                        Pricing
                      </Link>
                    </NavigationMenuItem>
                  </NavigationMenuList>
                </NavigationMenu>
              </div>
              <div className="flex justify-center gap-2">
                <Link
                  to="/v2/login"
                  className="flex items-center rounded-full p-1 px-6 hover:bg-gray-100"
                >
                  Login
                </Link>
                <Link to="/v2/login">
                  <RainbowButton className="rounded-full hover:shadow-[0_0_100px_#ffffff40_inset]">
                    Get Started
                  </RainbowButton>
                </Link>
              </div>
            </div>
          </nav>
        )}
      </div>
      <div className="-mt-20">
        <Outlet />
      </div>
    </div>
  );
}
